import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentRolesService, ISimpleDelinkPayload, ISimplPayload, ISimpleEligibility, CnpdataService, ILightBoxData, HeaderService, PageTypes, UserdataService, IAdditionalDetails, ITxnData, SimplScreen } from '../core';
import { environment } from 'src/environments/environment';
import shajs from 'sha.js';

@Component({
  selector: 'app-pay-later',
  templateUrl: './pay-later.component.html',
  styleUrls: ['./pay-later.component.scss']
})
export class PayLaterComponent implements OnInit {
  @Output()
  showHome: EventEmitter<boolean> = new EventEmitter<boolean>();
  payLaters: any = {};
  initiateContextPath = '';
  currentPayLater = 0;
  payLaterToDisplay: Array<any> = [];
  selectedOption: string;
  selectedValue = { payLater: '' };
  selectedImage = '';
  confirmContextPath = '';
  paymentFlowType = '';
  paymentInstrumentType: any;
  lightBoxData: ILightBoxData;
  lightboxEnable: boolean;
  PageTypes = PageTypes;
  lboxAdditionalDetails: IAdditionalDetails;
  simplEligibleToken: string = '';
  isOptionSelected: boolean = false;
  isEligible: boolean = true;
  additionalDetails: IAdditionalDetails = {} as IAdditionalDetails;
  availableCredit: number;
  creditAvailable: boolean = false;
  linkEnabled: boolean = false;
  isDropdownOpen: boolean = false;
  token: string;
  redirectionUrl: string;
  insufficientCredit: boolean = false;
  pendingDues: boolean = false;
  delinkAvailable: boolean = false;
  currentScreen = "";
  txnStatus = false;
  txnData: ITxnData = {} as ITxnData;
  transactionId = '';
  SimplScreen = SimplScreen;

  constructor(
    private paymentRole: PaymentRolesService,
    private cnpDataService: CnpdataService,
    public headerService: HeaderService,
    private userdataService: UserdataService,
  ) { }

  ngOnInit() {
    this.fetchRole();
    this.lightboxEnable = this.headerService.getPageType() === PageTypes.LB;
    if (this.lightboxEnable) {
      this.initLbox();
    }
    this.checkEligibility();
  }

  initLbox(): void {
    this.lightBoxData = this.userdataService.getLightboxInfo();
    this.lboxAdditionalDetails = this.userdataService.getAdditionalData();
    this.additionalDetails = this.userdataService.getAdditionalData();
  }

  gotoHome(clear?: boolean): void {
    this.showHome.emit(clear);
  }

  fetchRole() {
    const roles = this.paymentRole.getRoles();
    Object.keys(roles).forEach(role => {
      if (roles[role].paymentInstrumentType === "PayLater") {
        this.payLaters[role] = roles[role];
        this.payLaters[role].role = role;
        this.initiateContextPath = roles[role].initiatePaymentContextPath;
        this.paymentInstrumentType = roles[role].paymentInstrumentType
      }
    });
    this.doNext();
  }

  doNext() {
    const payLaterNames = Object.keys(this.payLaters).sort();
    for (this.currentPayLater = 0; this.currentPayLater < payLaterNames.length; this.currentPayLater++) {
      this.payLaterToDisplay.push(this.payLaters[payLaterNames[this.currentPayLater]]);
    }
  }

  setradio(payLater: string): void {
    this.selectedOption = payLater;
    this.selectedValue.payLater = payLater;
    this.selectedImage = this.getPayLaterLogoPath(this.payLaters[payLater].logo);
    this.initiateContextPath = this.payLaters[payLater].initiatePaymentContextPath;
    this.confirmContextPath = this.payLaters[payLater].confirmPaymentContextPath;
    this.paymentFlowType = this.payLaters[payLater].paymentFlowType;
    this.paymentInstrumentType = this.payLaters[payLater].paymentInstrumentType;
    this.isOptionSelected = true;
  }

  getPayLaterLogoPath(logoUrl: string): string {
    const url = new URL(logoUrl);
    const pathname = url.pathname;
    const filenameMatch = pathname.match(/\/([^\/?]+)$/);
    const filename = filenameMatch ? filenameMatch[1] : null;
    const cleanedFilename = filename ? filename.replace(/^paymentInstrument_/i, '').replace(/\.action$/i, '') : null;
    const logoPath = cleanedFilename ? `assets/images/${cleanedFilename}.png` : null;
    return logoPath;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  checkEligibility() {
    this.delinkAvailable = false;
    this.creditAvailable = false;
    let checkEligibilityPayLoad: ISimpleEligibility = {
      paymentChannelId: this.lightBoxData.paymentChannelId,
      applicationLabel: "Simpl",
      method: this.paymentInstrumentType,
      consumerMsisdn: "8095799666",
      amount: "1",
      merchantRedirectURL: `https://uat-r2.fnp.com/checkout/order-confirmation?orderId=${this.lightBoxData.orderId}`,
      uniqueReferenceId: this.lightBoxData.orderId,
      deviceParams: {
        deviceManufacturer: "Samsung",
        deviceModel: "FT-129459",
        platform: "android",
        ipAddress: "117.208.114.27",
        deviceResolution: "1728, 1117",
        deviceUpTime: "1100877165",
        carrierNetwork: "Jio",
        availableMemory: "7395",
        availableDiskSpace: "1666",
        fontSize: "11",
        parentApplicationName: "com.simpl.android",
        parentApplicationVersion: "6.6.4",
        userAgent: "Mozilla/5.0 (Linux; Android 13) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/110.0.5481.65 Mobile Safari/537.36",
        lastKnownLocation: "-22.629812,-11.583188",
        isRooted: true
      },
      merchantParams: {
        email: "acbd@abcd.com",
        firstTxnDate: "2007-05-10",
        userLoyaltyLevel: "1",
        userBlacklistFlag: "true",
        requestOriPage: "checkout",
        sellerIdentifier: "SELLER12345",
        sellerVintage: "6",
        sellerCategory: "Fashion"
      }
    };
    this.cnpDataService.checkEligibilityApiSimpl(checkEligibilityPayLoad).subscribe(resp => {
      if (resp.responseCode == "4460") {
        this.linkEnabled = true;
        this.isEligible = true;
        this.redirectionUrl = resp.actionURL;
      }
      else if (resp.responseCode == "4462") {
        this.insufficientCredit = true;
        this.isEligible = true;
        this.redirectionUrl = resp.actionURL;
      }
      else if (resp.responseCode == "4463") {
        this.pendingDues = true;
        this.isEligible = true;
        this.redirectionUrl = resp.actionURL;
      }
      else if (resp.payLaterStatus == "eligible" && resp.availableCredit != 0) {
        this.creditAvailable = true;
        this.availableCredit = resp.availableCredit;
        this.token = resp.payLaterToken;
        this.delinkAvailable = true
      }
    })
  }

  private extractDomainFromUrl(url: string): string {
    const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match !== null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2];
    } else {
      return '';
    }
  }

  proceedToPay() {
    if (this.linkEnabled == true || this.insufficientCredit == true || this.pendingDues == true) {
      window.open(this.redirectionUrl);
      return;
    }
    const checkSumPayLater = this.generateCheckSumforTxnStatus(this.lightBoxData.transactionAmount, this.lightBoxData.orderId, this.lightBoxData.paymentChannelId);
    const domainName = this.extractDomainFromUrl(environment.api_url);
    const primaryWebhookURL = `https://${domainName}/service-integrator/txnstatus/webhook`;
    const fallbackWebhookURL = `https://${domainName}/service-integrator/txnstatus/fallback/webhook`;
    const { customerFirstName = "", customerLastName = "" } = this.additionalDetails || {};
    let simplPayload: ISimplPayload = {
      method: this.paymentInstrumentType,
      methodDetails: {
        payLater: {
          applicationLabel: this.selectedOption,
          uniqueReferenceId: this.lightBoxData.orderId,
          payLaterToken: this.token
        }
      },
      amount: this.lightBoxData.transactionAmount,
      currencyCode: this.lightBoxData.currency,
      timezone: "India Standard Time",
      orderId: this.lightBoxData.orderId,
      checkSumPayment: checkSumPayLater,
      paymentChannelId: this.lightBoxData.paymentChannelId,
      skipOrderIdCheck: true,
      customerInfo: {
        customerCode: this.lightBoxData.customerCode,
        customerFirstName,
        customerLastName,
        emailAddress: this.lightBoxData.email,
        consumerMsisdn: "8095799666"
      },
      browserInfo: {
        javaEnabled: true,
        javascriptEnabled: true,
        timezoneOffset: 300,
        colorDepth: 24,
        screenWidth: 1920,
        screenHeight: 1080,
        ip: "105.106.107.108",
        referer: "https://merchansite.com/example/paybill",
        userAgent: "Mozilla/5.0",
        language: "en_US",
        acceptHeader: "text/html"
      },
      shippingAddress: {
        line1: "1-123",
        line2: "Financial street",
        city: "New York",
        state: "New York",
        country: "USA",
        pincode: "1234"
      },
      billingAddress: {
        line1: "1-123",
        line2: "Financial street",
        city: "New York",
        state: "New York",
        country: "USA",
        pincode: "1234"
      },
      redirectURL: {
        merchantRedirectURL: "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500",
        primaryWebhookURL,
        fallbackWebhookURL,
      },
      metadata: {
        sessionStartTime: "01/01/1999 12:00:00",
        initialCartValue: "1000.00",
        taxAmount: "1",
        couponApplied: "ABCD100",
        discountAmount: "100",
        items: [
          {
            sku: "ELEC12345",
            quantity: "1",
            ratePerItem: "500.00"
          }
        ]
      }
    };
    this.cnpDataService.simplInitiatePayment(simplPayload).subscribe(resp => {
      this.transactionId = resp.transactionId;
      if (resp.responseCode == "0000") {
        this.txnData.transactionId = this.transactionId;
        this.txnStatus = true;
      }
      else {
        this.txnStatus = false;
      }
      this.showTxnComplete();
    })
  }

  generateCheckSumforTxnStatus(amount, orderId, paymentChannelId) {
    let checksumInputString = "";
    checksumInputString = checksumInputString.concat(amount).concat(orderId).concat(paymentChannelId).concat("abcdefghhgfedcba");
    console.log('checksum', checksumInputString);
    return shajs('sha256').update(checksumInputString).digest('hex');
  }

  delink() {
    let simplDelinkPayload: ISimpleDelinkPayload = {
      applicationLabel: this.selectedOption,
      paymentChannelId: this.lightBoxData.paymentChannelId,
      // hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
      hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
      consumerMsisdn: "8095799666"
    }
    this.cnpDataService.delink(simplDelinkPayload).subscribe(resp => {
      if (resp.responseCode == "0000") {
        this.checkEligibility();
      }
    })
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.currentScreen = this.SimplScreen.COMPLETE;
  }
}
